<ng-container *ngIf="selectedCampaign$ | async"></ng-container>

<div
  id="linegraphPanel"
  class="panel full-width line-graph-panel"
  *transloco="let translate"
>
  <div class="panel-heading">
    <h5>{{ translate(lineTitle) }}</h5>

    @if (!campaignId && !targetGroupEntryId) {
    <select class="form-control selectItem" [formControl]="selectedCampaign">
      <option value="Active">{{ translate('Activecampaigns') }}</option>
      <option value="All">{{ translate('Allcampaigns') }}</option>
      <ng-container *ngFor="let item of allCampaigns">
        <option value="{{ item.title }}">{{ item.title }}</option>
      </ng-container>
    </select>
    }

    <div class="btn-group middle" role="group" aria-label="...">
      <button
        type="button"
        (click)="changeLineChartMode('views', selectedCampaign.value)"
        [ngClass]="{
          darkbutton: lineChartOptions.typeOfSelectedData === 'views'
        }"
        class="btn btn-default smallfontgroupb"
      >
        {{ translate('sort_impressions') }}
      </button>
      <button
        type="button"
        (click)="changeLineChartMode('clicks', selectedCampaign.value)"
        [ngClass]="{
          darkbutton: lineChartOptions.typeOfSelectedData === 'clicks'
        }"
        class="btn btn-default smallfontgroupb"
      >
        {{ translate('sort_clicks') }}
      </button>
      <button
        type="button"
        (click)="changeLineChartMode('clickRate', selectedCampaign.value)"
        [ngClass]="{
          darkbutton: lineChartOptions.typeOfSelectedData === 'clickRate'
        }"
        class="btn btn-default smallfontgroupb"
      >
        {{ translate('sort_rate') }}
      </button>
    </div>

    <div class="form-group has-feedback daterange-wrapper down form-inline">
      <div class="form-group">
        <div class="input-group">
          <input
            readonly
            name="calendar"
            class="calendar cursor-pointer"
            ngbDatepicker
            #calendar="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event, selectedCampaign.value)"
            [displayMonths]="2"
            [dayTemplate]="tooltipRange"
            outsideDays="hidden"
            value="{{ dateRange }}"
            [startDate]="fromDate!"
            (click)="calendar.toggle()"
          />
          <!-- Range tooltip -->
          <ng-template #tooltipRange let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
          <div class="input-group-append">
            <i class="glyphicon glyphicon-calendar form-control-feedback"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="clearfix main-section">
    <div class="linegraphdisplay">
      @if (lineChartOptions.typeOfSelectedData === 'clickRate') { @if
      (infiniteClickRate()) {
      <div class="count">{{ translate('CTR_empty') }}</div>
      } @else {
      <div class="count">{{ lineChartOptions.totalShown }} %</div>
      } } @else {
      <div
        class="count"
        placement="top"
        ngbTooltip="{{ lineChartOptions.totalShown | number: '1.0-0' }}"
      >
        {{ lineChartOptions.totalShown | shortNumber }}
      </div>
      } @switch (lineChartOptions.typeOfSelectedData) { @case ('views') {
      <div class="countlabel">{{ translate('sort_impressions') }}</div>
      } @case ('clicks') {
      <div class="countlabel">{{ translate('sort_clicks') }}</div>
      } @case ('clickRate') {
      <div class="countlabel">{{ translate('sort_rate') }}</div>
      } }
    </div>

    <ngx-charts-line-chart
      [customColors]="lineChartOptions.displayedColors"
      [results]="lineChartOptions.dataToShow"
      [gradient]="false"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="true"
      [legendPosition]="lineChartOptions.legendPosition"
      [legendTitle]="''"
      (select)="onSelectLegend($event)"
      [showGridLines]="true"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxisTickFormatting]="xTickFormatting"
      [animations]="true"
      [roundDomains]="true"
      [curve]="lineChartOptions.curve"
      style="fill: #ffffff"
      #lineChart
    >
      <!-- Tooltips -->
      <ng-template #tooltipTemplate let-model="model">
        <div class="text-start">
          @for (data of lineChartOptions.dataToShow; track $index) { @if
          (data.name === model.series) { @for (seriesData of data.series; track
          $index) { @if (seriesData.name === model.name) {
          <span><b>{{ seriesData.extra.label }}</b></span>
          <br />
          <span>{{ data.name }}</span>
          <br />
          <br />
          {{ translate('sort_impressions') }}: {{ seriesData.extra.impressions
          }}
          <br />
          {{ translate('sort_clicks') }}: {{ seriesData.extra.clicks }}
          <br />
          {{ translate('sort_rate') }}: {{ seriesData.extra.clickRate }} } } } }
        </div>
      </ng-template>
    </ngx-charts-line-chart>
  </div>
</div>
